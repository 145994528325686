// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-assault-airbike-review-js": () => import("./../../../src/pages/assault-airbike-review.js" /* webpackChunkName: "component---src-pages-assault-airbike-review-js" */),
  "component---src-pages-concept-2-bike-erg-review-js": () => import("./../../../src/pages/concept2-bike-erg-review.js" /* webpackChunkName: "component---src-pages-concept-2-bike-erg-review-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-rogue-echo-bike-review-js": () => import("./../../../src/pages/rogue-echo-bike-review.js" /* webpackChunkName: "component---src-pages-rogue-echo-bike-review-js" */),
  "component---src-pages-schwinn-airdyne-pro-review-js": () => import("./../../../src/pages/schwinn-airdyne-pro-review.js" /* webpackChunkName: "component---src-pages-schwinn-airdyne-pro-review-js" */),
  "component---src-pages-top-rated-air-bikes-2020-js": () => import("./../../../src/pages/top-rated-air-bikes-2020.js" /* webpackChunkName: "component---src-pages-top-rated-air-bikes-2020-js" */)
}

